@import "_vars";

input, button, [type="button"], [type="reset"], [type="submit"]  {
  font-family: "ヒラギノ角ゴ Pro W6","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  color: $color_text;
  appearance: none;
}

.disable {
  opacity: 0.4;
  pointer-events: none;
}

/* Text */
input[type="text"] {
  font-size: 14px;
  border: 1px solid $color_border_gray;
  padding: 4px 10px;
  @include max-screen($sp_size) {
    font-size: 16px;
    line-height: normal;
    border-radius: 0;
    padding: 3px 10px;
  }

  &.input_search {
    width: 100%;
    font-size: 13px;
    padding: 5px 130px 5px 45px;
    height: 48px;
    @include max-screen($sp_size) {
      font-size: 16px;
      padding: 7px 10px 7px 40px;
      height: auto;
    }
  }

  &.input_newsletter {
    width: calc(100% - 95px);
    max-width: 210px;
    height: 32px;      
    @include max-screen($sp_size) {
      width: calc(100% - 66px);
      max-width: none;
      padding: 3px 10px;
      vertical-align: top;
    }
  }
}


/* Button */
button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

a.btn {
  text-decoration: none;
  @include flexbox;
  align-items: center;
  justify-content: center;
}

.btn {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  transition: opacity .2s;
  @include min-screen($pc_size) {
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }
  @include max-screen($sp_size) {
    border-radius: 2px;
  }

  &.btn_default {
    background-color: #fff;
    border: 1px solid $color_main;
    color: $color_text;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  }
  &.btn_main {
    background-color: $color_main_dark;
  }
  &.btn_orange {
    background-color: $color_btn_orange;
    border: none;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  }
  &.btn_delete,
  &.btn_cancel {
    background-color: #fff;
    border: 1px solid $color_border_gray;
    color: $color_text;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  }
  &.btn_shadow {
    box-shadow: 1px 2px 3px rgba(0,0,0,0.3);
  }
  &.btn_inline {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    padding: 0 12px;
  }
  &.btn_inline_medium {
    display: inline-flex;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 15px;
    font-size: 16px;
    @include max-screen($sp_size) {
      padding: 0 10px;
    }
  }
  &.btn_220 {
    width: 220px;
    height: 30px;
  }
  &.btn_180 {
    width: 180px;
    height: 30px;
  }
  &.btn_150 {
    width: 150px;
    height: 30px;
  }
  &.btn_180 {
    width: 180px;
    height: 50px;
  }
  &.btn_290 {
    width: 290px;
    height: 50px;
    font-size: 21px;
    font-weight: normal;
    @include max-screen($sp_size) {
      width: 160px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &.btn_sp60 {
    @include max-screen($sp_size) {
      width: 60px;
      height: 30px;      
    }
  }
  &.btn_sp100 {
    @include max-screen($sp_size) {
      width: 100px;
      height: 30px;      
    }
  }
  &.btn_sp28vw {
    @include max-screen($sp_size) {
      width: 28vw;
      height: 30px;      
    }
  }

  &.btn_search {
    @include centering_vertically;
    right: 4px;
    width: 123px;
    height: 40px;
  }

  &.btn_newsletter {
    width: 83px;
    height: 32px;
    margin-left: 5px;
    @include max-screen($sp_size) {
      width: 66px;
      margin-left: 0;
      border-radius: 0;
    }
  }

  &.btn_print {
    width: 260px;
    height: 40px;
    margin: 40px auto 0;
    font-size: 16px;
    font-weight: normal;
    color: $color_main_text;
    border-radius: 2px;
    position: relative;
    padding-left: 20px;
    @include max-screen($sp_size) {
      display: none;
    }

    // &:before {
    //   content: "";
    //   display: block;
    //   width: 20px;
    //   height: 22px;
    //   background-image: url(../img/common/icon_print.svg);
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: contain;
    //   position: absolute;
    //   left: 15px;
    // }

    svg {
      width: 20px;
      height: auto;
      @include centering_vertically;
      left: 15px;
      path {
        fill: $color_text;
      }
    }
  }

  &.btn_review {
    background-color: $color_sort;
    font-size: 14px;
    font-weight: normal;
    @include max-screen($sp_size) {
      font-size: 10px;
      padding: 0 5px;
    }
  }

  &.inactive {
    background-color: $color_btn-disable;
    pointer-events: none;
  }
}



/* Checkbox & Radio */
input[type=radio],
input[type=checkbox] {
  display: none;
}

input[type=radio] + label,
input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

input[type=radio] + label {
  padding-left: 26px;
}

input[type=checkbox] + label {
  padding-left: 38px;
  @include max-screen($sp_size) {
    padding-left: 30px;
  }
}

input[type=radio] + label:before,
input[type=checkbox] + label:before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: #FFF;
  position: absolute;
  top: 50%;
  left: 0;
}

input[type=radio] + label:after,
input[type=checkbox] + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  box-sizing: border-box;
}

/* Radio */
input[type="radio"] + label:before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-radius: 50%;
  border: 1px solid $color_inputbox;
}

input[type="radio"]:checked + label:after {
  background-color: $color_main_dark;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  left: 2px;
}

// Radio with Frame
input[type=radio] + label.label_frame {
  padding: 6px 6px 6px 32px;
  border: 1px solid $color_inputbox;
  border-radius: 4px;
}

input[type="radio"] + label.label_frame:before {
  margin-left: 6px;
}

input[type="radio"]:checked + label.label_frame:after {
  left: 8px;
}

label.radio_in_label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color_frame;
  cursor: pointer;
  &.selected {
    border: 2px solid $color_frame-selected;
  }
  img {
    pointer-events: none;
  }
}

/* Checkbox */
input[type=checkbox] + label:before {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border: 3px solid $color_inputbox;
  @include max-screen($sp_size) {
    width: 25px;
    height: 25px;
    margin-top: -12px;
    border-width: 2px;
  }
}

input[type=checkbox]:checked + label:before {
  background-color: $color_main_dark;
  border-color: $color_main_dark;
}

input[type=checkbox]:checked + label:after {
  left: 6px;
  width: 18px;
  height: 12px;
  margin-top: -9px;
  border-left: 3px solid #FFF;
  border-bottom: 3px solid #FFF;
  transform: rotate(-45deg);
  @include max-screen($sp_size) {
    left: 7px;
    width: 12px;
    height: 8px;
    margin-top: -6px;
    border-width: 2px;
  }
}

/* Select Box */
select {
  font-size: 14px;
  padding: 4px 20px 4px 10px;
  appearance: none;
  background-image: url(../img/common/icon_pull_down.png);
  background-repeat: no-repeat;
  background-position: right 6px  center;
  border: 1px solid $color_inputbox;
  height: 30px;
  border-radius: 0;
  @include max-screen($sp_size) {
    font-size: 16px;
    background-image: url(../img/common/icon_pull_down_sp.png);
    background-size: 9px;
  }
}
select::-ms-expand {
  display: none;
}
.number_wrap {
  position: relative;
  input {
    width: 100%;
    height: 100%;
    padding: 10px 20px 10px 10px;
    border: 1px solid $color_main_dark;
    @include max-screen($sp_size) {
      padding: 5px;
      text-align: center;
      font-size: 16px;
    }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
  }
  .number_up,
  .number_down {
    width: 12px;
    height: 10px;
    position: absolute;
    cursor: pointer;
    @include max-screen($sp_size) {
      width: 25px;
      height: 25px;
    }
    img {
      max-height: 100%;
    }
  }
  .number_up {
    background-image: url(../img/common/icon_up.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 9px;
    right: 6px;
    @include max-screen($sp_size) {
      background-image: url(../img/common/icon_number_plus.png);
      top: 2px;
      right: -30px;
    }
  }
  .number_down {
    background-image: url(../img/common/icon_down.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    bottom: 9px;
    right: 6px;
    @include max-screen($sp_size) {
      background-image: url(../img/common/icon_number_minus.png);
      bottom: auto;
      top: 2px;
      left: -30px;
    }
  }
}

/* Search Icon & Button in Input Text */
.keyword_search {
  position: relative;
  .icon_search {
    @include centering_vertically;
    width: 18px;
    left: 16px;
    @include max-screen($sp_size) {
      width: 22px;
      transform: none;
      top: 8px;
      left: 10px;
    }
  }
  .btn_search {
    @include centering_vertically;
    width: 123px;
    height: 40px;
    right: 4px;
  }
}

/* Form Layout */
select,
input {
  &.parts_full {
    width: 100%;
  }
  &.parts_600 {
    width: 600px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_500 {
    width: 500px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_300 {
    width: 300px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_240 {
    width: 240px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_210 {
    width: 210px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_170 {
    width: 170px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_120 {
    width: 120px;
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
  &.parts_sp50 {
    @include max-screen($sp_size) {
      width: 50%;
    }
  }
  &.parts_sp90 {
    @include max-screen($sp_size) {
      width: 90px;
    }
  }
  &.parts_sp100 {
    @include max-screen($sp_size) {
      width: 100px;
    }
  }
  &.parts_sp_full {
    @include max-screen($sp_size) {
      width: 100%;
    }
  }
}

textarea {
  border: 1px solid $color_inputbox;;
  &.parts_600 {
    width: 600px;
    height: 200px;
    @include max-screen($sp_size) {
      width: 100%;
      height: 100px;
    }
  }  
}

.form_inner {
  margin-bottom: 40px;
  @include min-screen($pc_size) {
    padding-top: 10px;    
  }
}

.form_row {
  &:not(:last-of-type) {
    margin-bottom: 30px;
    @include max-screen($sp_size) {
      margin-bottom: 20px;
    }
  }

  label {
    font-size: 16px;
    font-weight: bold;
    @include max-screen($sp_size) {
      font-size: 14px;
    }
  }
  
  input {
    @include min-screen($pc_size) {
      height: 30px;
    }
  }
  
  .label {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: bold;
    @include max-screen($sp_size) {
      font-size: 14px;
    }
    .required {
      display: inline-block;
      width: 54px;
      height: 20px;
      line-height: 20px;
      border-radius: 4px;
      margin-left: 10px;
      background-color: $color_attention;
      color: #fff;
      font-size: 12px;
      text-align: center;
      vertical-align: top;
      @include max-screen($sp_size) {
        width: 40px;
        height: 16px;
        line-height: 16px;
        font-size: 10px;
        vertical-align: middle;
      }
    }
  }

  .form_parts {
    select,
    input[type="text"] {
      border-color: $color_inputbox;
    }

    &.form_parts_radio {
      @include flexbox;
      width: 100%;
      li {
        &:not(:last-of-type) {
          margin-right: 2em;
        }
      }

      &.form_parts_radio_lots {
        margin-top: 10px;
        @include max-screen($sp_size) {
          margin-top: 0;
          margin-bottom: -10px;
        }
        li {
          margin-bottom: 10px;

          &:not(:last-of-type) {
            margin-right: 1em;
            @include max-screen($sp_size) {
              margin-right: 0;
            }
          }

          @include max-screen($sp_size) {
            width: 49%;
            margin-bottom: 6px;
            &:nth-of-type(odd) {
              margin-right: 2%;
            }

            label {
              width: 100%;
            }
          }
        }
      }
    }

    &.form_parts_radio_sp_pile {
      @include max-screen($sp_size) {
        display: block;
        li {
          margin-top: 10px;
          &:not(:first-child) {
            @include max-screen($sp_size) {
              margin-left: 0;
            }        
          }
        }
      }
    }

    &.form_parts_pile {
      li {
        margin-top: 10px;
        label {
          margin-right: 10px;

          &[for="bill_point2"] {
            @include max-screen($sp_size) {
              margin-right: calc(100% - 180px);
              margin-bottom: 5px;

              & + input {
                margin-left: 26px;
              }
            }
          }
        }
      }
    }

    .error_message {
      display: none;
    }

    .example {
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      font-size: 14px;
      @include max-screen($sp_size) {
        font-size: 12px;
      }

      &.example_postalcode {
        @include max-screen($sp_size) {
          font-size: 10px;
        }
      }

      &.example2 {
        @include max-screen($sp_size) {
          margin-left: 0;
        }
      }
    }

    .input_parallel {
      @include flexbox;
      select {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      &.input_parallel_birth {
        select {
          @include max-screen($sp_size) {
            &:first-of-type {
              width: 34%;
            }
            &:not(:first-of-type) {
              width: 31%;
              margin-left: 2%;                  
            }
          }          
        }
      }

      &.input_parallel_expiration {
        select {
          @include max-screen($sp_size) {
            width: 49%;
            &:not(:first-of-type) {
              margin-left: 2%;  
            }            
          }
        }
      }
    }

    .btn_next_input {
      width: 140px;
      height: 30px;
      margin-left: 10px;
      font-weight: normal;
      @include max-screen($sp_size) {
        width: 65px;
      }
    }

    .example_postalcode + .btn_next_input {
      @include max-screen($sp_size) {
        width: 76px;
        margin-left: 9px;
      }
    }

    &.flexbox {
      .btn {
        font-size: 21px;
        font-weight: normal;

        &:not(:first-child) {
          margin-left: 10px;
        }

        @include max-screen($sp_size) {
          width: calc(50% - 5px);
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  &.form_row_wrap {
    @include flexbox;

    .form_parts {
      &:not(:first-child) {
        margin-left: 20px;
      }
      @include max-screen($sp_size) {
        width: 49%;
        &:not(:first-child) {
          margin-left: 2%;
        }
      }
    }
  }
}