@import "_vars";

body {
  font-family: "ヒラギノ角ゴ Pro W6","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  font-size: 12px;
  line-height: 1.5;
  color: $color_text;
}

* {
  box-sizing: border-box;
}

p, ul, dl, dd {
  margin: 0;
}

a {
  color: $color_text;
  @include min-screen($pc_size) {
    &:hover {
      color: $color_main_dark;
    }    
  }
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 20px;
  padding: 0 20px;
  line-height: 1.25;
  @include max-screen($sp_size) {
    font-size: 20px;
    margin: 0 0 20px;
    padding: 0 3%;
    color: $color_main_text;
  }

  .h1_result {
    font-size: 14px;
    margin-left: 10px;
    @include max-screen($sp_size) {
      font-size: 12px;
      margin-left: 0;
    }
  }

  .h1_sub {
    display: block;
    font-size: 12px;
    @include max-screen($sp_size) {
      display: none;
    }
  }
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px;
  text-align: center;
  @include max-screen($sp_size) {
    font-size: 16px;
    margin: 0 0 10px;

    span {
      display: none;
    }
  }
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 30px;
}

img {
  max-width: 100%;
  vertical-align: top;
}

ul {
  list-style: none;
  padding: 0;
}

.flexbox {
  @include flexbox;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

@include min-screen($pc_size) {
  .sp {
    display: none !important;
  }
}
@include max-screen($sp_size) {
  .pc {
    display: none !important;
  }
}

#responsive {
  display: none;
  float: right;
  @include max-screen($sp_size) {
    float:left;
  }
}

.attention {
  color: $color_attention;
}

.note {
  text-indent: -1em;
  padding-left: 1em;
}

.cross_out {
  text-decoration: line-through;
}

.error_message {
  color: $color_attention;
  font-size: 14px;
  font-weight: bold;
}

.ellipsis {
  @include max-screen($sp_size) {
    display: none;
  }
}

.bold {
  font-weight: bold;
}

.line_middle {
  @include flexbox;
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8;
  @include max-screen($sp_size) {
    font-size: 12px;
  }
  span {
    background-color: #fff;
    padding-right: 20px;
    @include max-screen($sp_size) {
      padding-right: 15px;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    border-bottom: 1px dotted $color_border_gray;
    position: relative;
    top: -16px;
    left: 0;
    z-index: -1;
    @include max-screen($sp_size) {
      top: -12px;
    }
  }
}

.support_tell {
  color: $color_main_text;
  font-size: 20px;
  font-weight: bold;
  @include max-screen($sp_size) {
    font-size: 24px;
    padding: 5px 10px;
  }
  a {
    color: $color_main_text;
  }
  img {
    width: 18px;
    margin-right: 10px;
    vertical-align: middle;
    @include max-screen($sp_size) {
      width: 20px;
    }
  }
}

.fax {
  color: $color_main_text;
  font-size: 20px;
  font-weight: bold;
  @include max-screen($sp_size) {
    font-size: 24px;
    padding: 5px 0;
  }
  img {
    width: 22px;
    margin-right: 10px;
    vertical-align: middle;
    @include max-screen($sp_size) {
      width: 25px;
    }
  }
}

.tel_link {
  text-decoration: none;
  @include min-screen($pc_size) {
    pointer-events: none;
  }
}

/* Icon */
.icon_question,
.icon_help {
  display: inline-block;
  margin-left: 8px;
  width: 18px;
  vertical-align: middle;
  @include max-screen($sp_size) {
    width: 19px;
    margin-left: 6px;
  }
  img {
    width: 100%;
  }
}


/* Wrapper
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.overlay {
  display: none;
}
@include max-screen($sp_size) {
  .wrapper {
    width: 100%;
    overflow: hidden;
  }

  .spWrapper {
    transition: all .5s;

    .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 90;
    }
  }

  .wrapper {
    &.open {
      position: fixed;
      .spWrapper {
        transform: translateX(85%);
        .overlay {
          display: block;
        }
      }
    }    
  }
}


/* Header 
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
header {
  max-width: $contents_width;
  margin: auto;
  @include max-screen($sp_size) {
    width: 100%;
  }

  .header_top {
    text-align: center;
    p {
      display: inline-block;
      background-image: url(../img/common/icon_shipping.png);
      background-repeat: no-repeat;
      background-position: left center;
      padding: 20px 0 20px 40px;
      font-size: 13px;
      @include max-screen($sp_size) {
        background-size: 20px;
        padding: 4px 0 4px 25px;
        font-size: 10px;
      }

      a {
        color: $color_text_black;
        font-weight: bold;
        margin-left: 2px;
        @include max-screen($sp_size) {
          font-size: 11px;
        }
      }
    }
  }

  .header_banner {
    text-align: center;
    background-color: $color_main;

    p {
      padding: 15px 0;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      @include max-screen($sp_size) {
        padding: 4px 0;
        font-size: 12px;
      }

      a {
        font-size: 13px;
        color: #fff;
        margin-left: 10px;
        font-weight: normal;
        @include max-screen($sp_size) {
          font-size: 10px;
          display: block;
          margin-left: 0;
        }
      }      
    }
  }

  .header_inner {
    @include max-screen($sp_size) {
      width: $contents_width_sp;
      margin: auto;
      padding-top: 20px;
    }

    .header_user {
      @include flexbox;
      justify-content: flex-end;
      padding: 0 20px;
      margin: 10px 0 20px;
      @include max-screen($sp_size) {
        display: none;
      }

      .header_user_list {
        position: relative;
        padding-bottom: 10px;

        &:not(:first-child) {
          margin-left: 10px;
          &::before {
            content: "|";
            display: inline;
            margin-right: 10px;
            color: #000;
          }
        }

        .header_user_heading {
          display: inline-block;
          background-image: url(../img/common/icon_arrow_down.png);
          background-repeat: no-repeat;
          background-position: right center;
          padding-right: 15px;
          border-bottom: 1px solid #000;
          cursor: pointer;
        }

        .header_user_lower {
          display: none;
          position: absolute;
          top: 25px;
          left: 0;
          z-index: 100;
          background-color: #fff;
          border: 1px solid #000;
          padding: 15px 20px;
          width: 14em;

          li {
            &:not(:first-child) {
              margin-top: 15px;
            }

            a {
              text-decoration: none;
            }
          }
        }

        &:last-child {
          .header_user_lower {
            left: auto;
            right: 0;
          }
        }

        &.active {
          .header_user_lower {
            display: block;
          }
        }
      }
    }

    .header_main {
      @include flexbox;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      @include max-screen($sp_size) {
        display: block;
        position: relative;
        padding: 45px 0 0;
      }

      .menu_open {
        display: none;
        @include max-screen($sp_size) {
          display: block;
          width: 37px;
          height: 35px;
          background-image: url(../img/common/icon_menu_open.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0;

          // &.close {
          //   background-image: url(../img/common/icon_menu_close.svg);
          //   z-index: 100;
          // }
        }
      }

      .header_logo {
        @include max-screen($sp_size) {
          @include centering_horizontally;
          top: 0;
          width: 150px;
        }
      }

      .header_search {
        // width: 720px;
        width: calc(100% - 480px);
        max-width: 720px;
        min-width: 380px;
        @include max-screen($sp_size) {
          width: 100%;
          max-width: none;
          min-width: auto;
        }

        form {
          width: 100%;
          height: 100%;

          .btn_search {
            @include max-screen($sp_size) {
              display: none;
            }
          }
        }

        .search_suggest {
          display: none;
          background-color: #fff;
          border: 1px solid $color_border_lightgray;
          padding: 10px;
          position: absolute;
          top: 50px;
          z-index: 100;
          &.active {
            @include flexbox;
            @include max-screen($sp_size) {
              display: block;
              background-color: transparent;
              border: none;
              padding: 0;
              top: 45px;
            }
          }

          .search_suggest_result {
            width: 360px;
            @include max-screen($sp_size) {
              width: 100%;
              padding: 10px 10px 0;
              background-color: #fff;
            }

            dl {
              @include flexbox;
              font-size: 14px;
              padding: 5px 0 10px;
              border-bottom: 1px solid $color_border_lightgray;

              dt {
                font-weight: bold;
              }
              dd {
                a {
                  margin: 0 5px;
                }
              }
            }

            ul {
              padding: 10px 0;
              li {
                a {
                  @include flexbox;
                  padding: 5px 0;
                  text-decoration: none;
                  &:hover {
                    background-color: $color_bg_gray;
                  }

                  .search_suggest_result_img {
                    width: 60px;
                    @include max-screen($sp_size) {
                      width: 50px;
                    }
                  }

                  .search_suggest_result_detail {
                    width: calc(100% - 70px);
                    margin-left: 10px;
                    line-height: 1.5;
                  }                  
                }

                &:not(:first-child) {
                  border-top: 1px solid $color_border_lightgray;
                }
              }
            }
          }

          .search_suggest_category {
            width: 180px;
            margin-left: 10px;
            background-color: $color_bg_gray;
            padding: 10px;
            @include max-screen($sp_size) {
              width: 100%;
              margin-left: 0;
              margin-top: 5px;
              background-color: #fff;
            }

            dl {
              dt {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 5px;
              }
              dd {
                margin-bottom: 5px;
                a {
                  font-size: 14px;
                }
              }
            }
          }

          &:before {
            border: 14px solid transparent;
            border-bottom-color: #fff;
            border-top-width: 0;
            border-right-width: 9px;
            border-left-width: 9px;
            top: -13px;
            content: "";
            display: block;
            left: 60px;
            margin-left: -10px;
            position: absolute;
            width: 0;
            z-index: 1;
          }
          &:after {
            border: 16px solid transparent;
            border-bottom-color: #DDD;
            border-top-width: 0;
            border-right-width: 10px;
            border-left-width: 10px;
            top: -16px;
            content: "";
            display: block;
            left: 60px;
            margin-left: -11px;
            position: absolute;
            width: 0;
          }
        }
      }

      .mini-cart {
        position: relative;
        @include max-screen($sp_size) {
          position: absolute;
          top: 0;
          right: 2%;
        }
        
        .header_bag {
          text-decoration: none;

          .icon_shoppingBag {
            width: 31px;
            color: $color_main_dark;
            vertical-align: bottom;
            @include max-screen($sp_size) {
              width: 25px;
            }

            /* Hack IE */
            @media all and (-ms-high-contrast: none){
              height: 38px;            
            }
          }

          span {
            font-size: 13px;
            font-weight: bold;
            margin-left: 5px;
            @include max-screen($sp_size) {
              display: none;
            }
          }

          &:hover {
            color: $color_text_hover;
            .icon_shoppingBag {
              color: $color_text_hover;            
            }
          }
        }

        .mini-cart-content {
          display: none;
          background-color: #fff;
          border: 1px solid #ccc;
          box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.3);
          position: absolute;
          right: 0;
          top: 38px;
          width: 18rem;
          z-index: 100;
          line-height: 1.3;

          @include max-screen($sp_size) {
            max-width: 220px;            
          }

          a {
            text-decoration: none;
          }

          .mini-cart-header {
            font-size: 1.1em;
            font-weight: 700;
            padding: 1em 1em 0.5em;
            text-transform: uppercase;
          }

          .mini-cart-products {
            max-height: 360px;
            padding: 0.5em;
            overflow: auto;

            .mini-cart-product {
              overflow: hidden;
              padding: 0.5em;
              position: relative;
              &:not(:last-of-type) {
                border-bottom: 1px solid #aaa;                
              }

              .mini-cart-toggle {
                cursor: pointer;
                font-size: 1.5em;
                position: absolute;
                left: 0;
                top: 0;
              }

              .mini-cart-image {
                float: left;
                width: 50%;

                img {
                  max-width: 90%;
                }
              }

              .mini-cart-name {
                float: right;
                font-style: italic;
                width: 50%;

                a {
                  color: #84bd00;
                }
              }

              .mini-cart-attributes {
                float: right;
                font-size: 0.9em;
                font-weight: bold;
                width: 50%;
              }

              .mini-cart-pricing {
                clear: right;
                float: right;
                font-size: 0.9em;
                width: 50%;
              }

              &.collapsed {
                .mini-cart-image {
                  display: none;
                }

                .mini-cart-name {
                  width: 90%;
                }

                .mini-cart-attributes {
                  width: 90%;
                }

                .mini-cart-pricing {
                  width: 90%;
                }
              }
            }
          }

          .mini-cart-totals {
            text-align: center;
            .mini-cart-subtotals {
              font-weight: 700;
              margin: 0 auto;
              padding-top: .5em;
              width: 16rem;
              width: max-content;
            }

            .mini-cart-slot {
              background: none repeat scroll 0 0 #e0e0e0;
              border: 1px solid #e0e0e0;
              font-size: 0.9em;
              font-style: italic;
              margin: 1em 1em;
            }

            .mini-cart-link-cart {
              background-color: $color_btn_orange;
              border-radius: 2px;
              color: #fff;
              cursor: pointer;
              display: inline-block;
              font-size: 1.1em;
              font-weight: 400;
              padding: 0.5em 2em;
              text-align: center;
              transition: all 0.3s ease;
            }

            .mini-cart-link-checkout {
              color: #84bd00;
              clear: both;
              display: block;
              margin: 1em 0;
              text-align: center;
            }
          }
        }

        &:hover {
          .mini-cart-content {
            display: block;
          }
        }
      }
    }

    nav {
      border-bottom: 1px solid $color_border_gray;
      @include max-screen($sp_size) {
        display: none;
        border-bottom: none;
      }
      > ul {
        @include flexbox;
        align-items: center;
        margin-top: 30px;
        @include max-screen($sp_size) {
          justify-content: center;
          margin-top: 15px;
          text-decoration: underline;
        }

        > li {
          text-align: center;
          line-height: 1.25;
          width: calc(100% / 8);
          height: 50px;
          @include max-screen($sp_size) {
            display: block;
            width: auto;
            height: auto;
          }
          &:after {
            content: "";
            display: block;
            height: 0;
            visibility: hidden;
            clear: both;
          }

          &:not(:first-child) {
            @include max-screen($sp_size) {
              margin-left: 5%;
            }
          }

          &.current {
            background-color: $color_bg_gray;
          }

          a {
            display: inline-block;
            text-decoration: none;
          }
          
          > a {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 14px;
            color: $color_text_black;
          }

          .level-2 {
            display: none;
            clear: left;
            text-align: left;
            @include min-screen($pc_size) {
              background-color: #fff;
              box-shadow: 0 3px 3px 2px rgba(66, 66, 66, 0.3);
              color: #333;
              padding: 0;
              position: absolute;
              z-index: 1;

             a:hover {
                text-decoration: underline;
              }

              > ul {
                float: left;
                padding-bottom: .5em;
                &:after {
                  content: "";
                  display: block;
                  height: 0;
                  visibility: hidden;
                  clear: both;
                }

                > li {
                  min-width: 12em;
                  margin-bottom: 1em;

                  > a {
                    padding: 0.5em 1rem;
                    color: #84bd00;
                  }

                  .level-3 {
                    padding-left: 2em;

                    li {
                      float: none;
                      padding: 0.4em;                      
                    }
                  }
                }

                &.menu-horizontal {
                  float: none;
                  @include flexbox;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding-left: 0;

                  > li {
                    border-right: 1px solid #e0e0e0;
                    padding-right: 2rem;
                    &:last-child {
                      border-right: none;
                    }
                  }
                }

                &.menu-vertical {

                }
              }

              .banner {
                float: left;
              }
            }
          }

          &:hover {
            .level-2 {
              display: block;
            }
          }
        }
      }
    }    
  }
}

/* Main
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
main {
  max-width: $contents_width;
  margin: auto;
  padding: 20px 0 100px;
  position: relative;
  @include max-screen($sp_size) {
    width: 100%;
    max-width: none;
    padding: 20px 0 60px;
  }
}

/* Breadcrumb */
.breadcrumb {
  @include flexbox;
  padding: 0 20px;
  margin-bottom: 40px;
  
  li {
    &:not(:first-child) {
      margin-left: 5px;
      &:before {
        content: ">";
        display: inline;
        margin-right: 5px;
      }
    }
    &:last-child {
      font-weight: bold;
    }
  }
  @include max-screen($sp_size) {
    display: none;
  }
}

/* SP Inner */
@include max-screen($sp_size) {
  .contents_wrapper,
  .login_wrap,
  .register_wrapper,
  .payment_wrapper,
  .main_inner {
    padding: 0 3%;
  }    
}

/* Payment Step */
.step {
  @include flexbox;
  margin-bottom: 40px;
  @include min-screen($pc_size) {
    padding: 10px 20px 0;
  }
  @include max-screen($sp_size) {
    justify-content: center;
    padding: 0 3%;
    margin-bottom: 30px;
  }


  p,
  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    position: relative;
    padding-bottom: 7px;
    @include max-screen($sp_size) {
      font-size: 10px;
      padding-bottom: 2px;
    }
    &.current {
      border-bottom: 4px solid $color_border_orange;
      @include max-screen($sp_size) {
        border-width: 2px;
      }
    }

    span {
      @include max-screen($sp_size) {
        display: none;
      }
    }
  }

  .step_line {
    width: 30px;
    height: 0;
    border-bottom: 1px solid #cacaca;
    position: relative;
    top: 9px;
    left: 0;
    margin: 0 10px;
    @include max-screen($sp_size) {
      width: 10px;
      margin: 0 6px;
      top: 6px;
    }
  }
}

/* 2 Column Layout */
.contents_wrapper {
  padding-bottom: 40px;
  @include max-screen($sp_size) {
    margin: auto;
    padding-bottom: 20px;
  }

  aside {
    width: 18.5%;
    margin-right: 1.5%;
    float: left;
    @include max-screen($sp_size) {
      width: 100%;
      float: none;
    }
  }
  article {
    width: 80%;
    float: left;
    @include max-screen($sp_size) {
      width: 100%;
      float: none;
    }
  }
  
  &.reverse {
    article {
      float: right;
      @include max-screen($sp_size) {
        float: none;
      }
    }
  }

  h1.h1_underline {
    @include max-screen($sp_size) {
      width: $contents_width_sp;
      margin: auto;
      padding: 0 0 10px 3%;
      border-bottom: 1px solid $color_border_gray;      
    }
  }
}
.page_item-list {
  .contents_wrapper {
    h1 {
      @include min-screen($pc_size) {
        border-bottom: none;
        padding: 0;
      }
    }
  }
}

/* 1 Column Layout */
.container {
  margin-top: 40px;
  @include max-screen($sp_size) {
    width: $contents_width_sp;
    margin: 20px auto 0;
  }
}
.contents_wrapper + .suggest_area {
  border-top: 1px solid $color_border_gray;
  margin-top: 0;
  padding-top: 40px;
  @include max-screen($sp_size) {
    padding-top: 30px;
  }
}

/* Cart / Message Pages */
.main_inner {
  padding: 0 20px;
  @include max-screen($sp_size) {
    padding: 0 3%;
  }
}
// Message Only
.message_page {
  margin-bottom: 80px;
  @include max-screen($sp_size) {
    margin-bottom: 40px;
  }

  p {
    margin: 40px 0;
    font-size: 18px;
    @include max-screen($sp_size) {
      margin: 20px 0;
      font-size: 14px;
    }

    &.order_number {
      font-size: 32px;
      @include max-screen($sp_size) {
        font-size: 22px;
        text-align: center;
      }
    }
  }
  .btn {
    width: 320px;
    height: 60px;
    font-size: 21px;
    font-weight: normal;
    @include max-screen($sp_size) {
      width: 100%;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

/* Item Thumbnail */
.item_thumbnail_img {
  margin-bottom: 5px;
  height: auto;

  img {
    max-height: 100%;
  }
}
.item_thumbnail_name {
  display: block;
  margin-bottom: 5px;
  @include max-screen($sp_size) {
    white-space: normal;
    font-size: 10px;
  }
}
a.item_thumbnail_name {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.item_thumbnail_rating {
  margin-bottom: 5px;
}

.item_thumbnail_price {
  @include max-screen($sp_size) {
    white-space: normal;
  }

  .special_price {
    color: $color_attention;
    font-size: 11px;
    font-weight: bold;
    margin-top: 5px;
    @include max-screen($sp_size) {
      font-size: 12px;
    }
  }
}

/* Suggest Items */
.suggest_area {
  @include max-screen($sp_size) {
    margin-top: 30px;
  }

  &:not(.recent) {
    .suggest_area_inner {
      @include flexbox;
      flex-wrap: nowrap;
      @include max-screen($sp_size) {
        white-space: nowrap;
        width: auto;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 10px;
      }

      .suggest_item {
        width: 20%;
        text-align: center;
        @include max-screen($sp_size) {
          min-width: 120px;
          padding: 0 2px;
        }

        &:not(:first-child) {
          @include min-screen($pc_size) {
            margin-left: 40px;
          }
        }

        a {
          display: block;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }

          @include max-screen($sp_size) {
            // display: block;
            // width: 120px;
          }
        }
      }
    }
  }

  &.recent {
    .suggest_area_inner {
      @include min-screen($pc_size) {
        @include flexbox;
        flex-wrap: nowrap;
      }

      .suggest_item {
        text-align: center;            
        @include min-screen($pc_size) {
          width: 20%;
          &:not(:first-child) {
            margin-left: 40px;
          }
        }
        @include max-screen($sp_size) {
          margin: 0 3px;
        }


        a {
          display: block;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }

          .item_thumbnail_img {
            @include min-screen($pc_size) {
              margin-bottom: 5px;
              img {
                max-height: 100%;
              }
            }
          }

          .item_thumbnail_name {
            margin-bottom: 5px;
            @include max-screen($sp_size) {
              display: none;
            }
          }
        }

        .item_thumbnail_rating {
          margin-bottom: 5px;
          @include max-screen($sp_size) {
            display: none;
          }
        }

        .item_thumbnail_price {
          @include max-screen($sp_size) {
            display: none;
          }

          .special_price {
            color: $color_attention;
            font-size: 11px;
            font-weight: bold;
            margin-top: 5px;
          }
        }
      }
    }
  }
}// .suggest_area

// View More
.view_more {
  text-align: right;
  margin-top: 20px;
  font-size: 13px;
  @include max-screen($sp_size) {
    text-align: center;
    margin-top: 10px;
  }
  a {
    text-decoration: underline; 
    @include max-screen($sp_size) {
      display: block;
      text-decoration: none;
      border: 1px solid $color_border_gray;
      box-shadow: 0 1px 2px rgba(0,0,0,0.2);
      padding: 5px 0;
    }
  }
}

.page_search,
.page_item-list {
  .suggest_area {
    &.recent {
      @include max-screen($sp_size) {
        border-bottom: 1px solid $color_border_gray;        
      }
    }
  }
}

/* Recently Checked Items */
.recent_slider.slick-slider {
  @include max-screen($sp_size) {
    margin-bottom: 50px;

    .slick-list {
      .slick-slide {
        .item_thumbnail_img {
          height: auto;
        }
      }
    }

    .slick-arrow {
      width: 12px;
      z-index: 100;

      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
      &:before {
        content: none;
      }

      svg {
        fill: $color_main_dark;
      }
    }

    .slick-dots {
      @include max-screen($sp_size) {
        bottom: -25px;
      }
      li {
        margin: 0 1%;

        button {
          &::before {
            color: $color_border_gray;
            opacity: 1;
            font-size: 12px;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: $color_main_dark;
            }
          }
        }
      }
    }
  }
}

/* Feature List */
.feature {
  @include flexbox;
  @include max-screen($sp_size) {
    display: block;
  }

  .feature_box {
      width: calc(33.33% - 20px);
      max-width: 400px;
    @include max-screen($sp_size) {
      width: 100%;
      max-width: none;
    }

    &:nth-child(n + 4) {
      @include min-screen($pc_size) {
        margin-top: 30px;        
      }
    }

    &:not(:nth-child(3n)) {
      @include min-screen($pc_size) {
        margin-right: 30px;
      }
    }

    &:not(:first-child) {
      @include max-screen($sp_size) {
        margin-top: 12px;
      }
    }

    a {
      display: block;
      img {
        width: 100%;
      }
    }

    div {
      margin-top: 10px;
      line-height: 1.6;
      @include max-screen($sp_size) {
        margin-top: 8px;
        line-height: 1.4;
      }

      .feature_title {
        font-size: 14px;
        font-weight: bold;

        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .feature_description {
        margin-top: 10px;
        @include max-screen($sp_size) {
          display: none;
        }
      }
    }
  }
}

/* Special Banner */
.special {
  @include flexbox;
  justify-content: center;
  @include max-screen($sp_size) {
    display: block;
    padding: 10px 0;
  }

  div {
    width: calc(33.33% - 20px);
    max-width: 400px;
    margin-bottom: 10px;
    @include max-screen($sp_size) {
      width: 100%;
      max-width: none;
      margin-bottom: 0;
    }

    &:not(:nth-child(3n)) {
      @include min-screen($pc_size) {
        margin-right: 30px;
      }
    }

    &:not(:first-child) {
      @include max-screen($sp_size) {
        margin-top: 5px;
      }
    }

    a {
      img {
        width: 100%;
      }
    }
  }
}

/* Item Categories */
.category_area {
  @include flexbox;
  margin-top: 40px;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  dl {

    @include min-screen($pc_size) {
      width: calc(33.33% - 20px);
      max-width: 400px;
      border: 1px solid $color_border_lightgray;
      padding: 0 10px;
      &:not(:nth-of-type(3n + 1)) {
        margin-left: 30px;
      }

      &:nth-of-type(n + 4) {
        margin-top: 20px;
      }
    }

    @include max-screen($sp_size) {
      border-top: 1px solid $color_border_lightgray;
      width: 100%;
      &:last-child {
        border-bottom: 1px solid $color_border_lightgray;
      }
    }

    dt {
      padding: 25px 0;
      border-bottom: 1px dotted $color_border_lightgray;
      @include max-screen($sp_size) {
        border-bottom: none;
        padding: 20px 10px 20px 25px;
        background-position: 10px center;

        &.active {
          background-color: $color_bg_gray;
        }
      }

      a {
        color: $color_main_dark;
        font-size: 14px;
        font-weight: bold;

        svg {
          width: 8px;
          height: 12px;
          margin-right: 8px;
          fill: $color_main_dark;
          @include max-screen($sp_size) {
            display: none;
          }
        }
      }
    }

    dd {
      padding: 20px 0 10px;
      @include max-screen($sp_size) {
        display: none;
        padding: 0;
      }

      ul {
        @include min-screen($pc_size) {
          @include flexbox;            
        }
      }
    }
  }
}
.contents_wrapper + .category_area {
  @include max-screen($sp_size) {
    margin-top: 20px;
  }
}

/* Free Shipping */
.category_free_shipping {
  @include max-screen($sp_size) {
    margin-top: 30px;
    img {
      width: 100%;
    }
  }
}
h1 + .category_free_shipping {
  @include min-screen($pc_size) {
    margin: 20px auto;
  }
  @include max-screen($sp_size) {
    width: 100%;
    margin: 0 auto 20px;
  }
}

/* Category Description */
.category_description {
  margin-top: 40px;
  padding: 30px 20px 10px;
  border-top: 1px solid $color_border_gray;
  font-size: 13px;
  @include max-screen($sp_size) {
    display: none;
  }

  p {
    span {
      font-weight: bold;
    }
  }
  dl {
    @include flexbox;
    margin-top: 20px;
    dt {
      width: 100%;
      margin-bottom: 5px;
      font-weight: bold;
    }
    dd {
      a {
        text-decoration: none;
        padding-left: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
      &:first-of-type {
        a {
          padding-left: 0;
        }
      }
      &:not(:last-of-type) {
        a {
          &:after {
            content: "|";
            display: inline;
            font-size: 13px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

/* Submit Area */
.submit_area {
    width: 100%;
    border-top: 1px solid $color_border_gray;
    padding-top: 40px;
    text-align: center;
    .btn {
      width: 520px;
      height: 60px;
      font-size: 21px;
      @include max-screen($sp_size) {
        width: 100%;
        font-size: 18px;
        height: 50px;
      }
    }
    p {
      text-align: center;
      margin-top: 20px;
    }
  }


/* Back To Page Top */
.page_top {
  display: none;
  text-decoration: none;
  text-align: center;
  font-size: 10px;
  width: 70px;
  height: 70px;
  padding-top: 15px;
  background-color: #fff;
  border: 1px solid $color_border_gray;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 100;
  @include max-screen($sp_size) {
    width: 40px;
    height: 40px;
    margin-right: 2%;
  }
  img {
    width: 25px;
    @include max-screen($sp_size) {
      width: 20px;
    }
  }
  span {
    display: block;
    margin-top: 8px;
    @include max-screen($sp_size) {
      display: none;
    }
  }
}

/* Open/Close Switch Button*/
.switch_button {
  @include min-screen($pc_size) {
    display: none;
  }
  @include max-screen($sp_size) {
    float: right;
    width: 17px;
    height: 17px;
    background-image: url(../img/common/icon_open_sp.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      background-image: url(../img/common/icon_close_sp.png);
    }
  }
}

.switch_button_local {
  @include min-screen($pc_size) {
    display: none;
  }
  @include max-screen($sp_size) {
    float: right;
    width: 17px;
    height: 17px;
    background-image: url(../img/common/icon_open_sp.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      background-image: url(../img/common/icon_close_sp.png);
    }
  }
}

/* Modal */
.mfp-wrap {
  .mfp-container {
    .mfp-content {
      width: 90%;
      max-width: 1000px;
      @include max-screen($sp_size) {
        width: 94%;
        max-width: none;
      }

      .mfp-close {
        color: #fff;
        opacity: 1;
        font-family: inherit;
        font-size: 50px;
        width: 60px;
        height: 60px;
        right: 10px;
        top: 40px;
        @include max-screen($sp_size) {
          font-size: 30px;
          width: 40px;
          height: 48px;
          right: 0;
        }
      }

      .modal_editor {
        width: 100%;
        // max-height: 84vh;
        margin: 40px 0;
        background-color: #fff;
        overflow-y: auto;

        .modal_header {
          background-color: $color_main_dark;
          color: #fff;
          font-size: 26px;
          font-weight: bold;
          padding: 12px 40px;
          @include max-screen($sp_size) {
            font-size: 20px;
            padding: 10px 40px 10px 10px;
          }
        }

        .modal_inner {
          padding: 30px 40px 40px;
          @include max-screen($sp_size) {
            padding: 20px 10px 30px;
          }

          .moda_headline {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            @include max-screen($sp_size) {
              font-size: 14px;
            }
          }

          .moda_text {
            font-size: 16px;
            line-height: 1.8;
            margin-bottom: 20px;
            @include max-screen($sp_size) {
              font-size: 14px;
              line-height: 1.4;
            }
          }

          .form_inner {
            margin-bottom: 0;
            @include min-screen($pc_size) {
              padding-top: 0;
            }
          }

          .modal_bg_gray {
            @include min-screen($pc_size) {
              background-color: $color_bg_gray;
              padding: 20px;              
            }
          }

          .modal_complete {
            display: none;
            .btn {
              @include max-screen($sp_size) {
                width: 170px;
              }
            }
          }

          .center_image {
            text-align: center;
            padding: 20px 0;
            @include max-screen($sp_size) {
              padding: 10px 0;
            }
          }

          .modal_f14 {
            font-size: 14px;
            @include max-screen($sp_size) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}


/* Footer
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
footer {
  max-width: $contents_width;
  margin: auto;
  @include max-screen($sp_size) {
    width: 100%;
  }

  .footer_block {
    background-color: $color_bg_gray;

    .footer_inner {
      padding: 20px;
      @include max-screen($sp_size) {
        padding: 0;
      }
    }

    &:first-child {
      .footer_inner {
        @include flexbox;
        justify-content: space-between;        
        @include max-screen($sp_size) {
          display: block;
        }
        .footer_nav {
          &:nth-of-type(1) {
            width: 26%;
          }
          &:nth-of-type(2) {
            width: 26%;
          }
          &:nth-of-type(3) {
            width: 23%;
          }
          &:nth-of-type(4) {
            width: 25%;
          }
          @include max-screen($sp_size) {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              width: 100%;
            }

            &:not(:last-child) {
              border-bottom: 1px solid $color_border_gray;
            }
          }

          .footer_heading {
            color: $color_main_dark;
            font-size: 14px;
            font-weight: bold;

            @include max-screen($sp_size) {
              color: $color_text;
              padding: 12px 10px;
            }

            &.no_lower {
              margin-top: 12px;
              @include max-screen($sp_size) {
                border-top: 1px solid $color_border_gray;
                margin-top: 0;
              }

              a {
                text-decoration: none;
                color: $color_main_dark;
                display: block;
                position: relative;
                @include max-screen($sp_size) {
                  color: $color_text;
                }

                svg {
                  width: 10px;
                  fill: $color_text;
                  @include centering_vertically;
                  right: 2px;
                  display: none;
                  @include max-screen($sp_size) {
                    display: block;
                  }                
                }
              }
            }

            span {
              display: none;
              @include max-screen($sp_size) {
                display: block;
                float: right;
              }
            }
          }

          ul:not(.sns) {
            @include flexbox;
            @include max-screen($sp_size) {
              display: none;
            }

            li {
              margin-top: 12px;
              margin-right: 30px;

              @include max-screen($sp_size) {
                margin-top: 0;
                margin-right: 0;
                padding: 10px 0 10px 2em;
                border-top: 1px solid $color_border_gray;
              }

              a {
                font-size: 13px;
                @include max-screen($sp_size) {
                  text-decoration: none;
                }
              }
            }
          }

          .sns {
            @include flexbox;
            align-items: center;
            margin-top: 10px;
            @include max-screen($sp_size) {
              margin: 15px 10px 0;
              padding: 15px 0;
              border-top: 1px solid #fff;
              border-bottom: 1px solid #fff;
            }

            li {
              width: 16px;
              margin: 0 8px;
              @include max-screen($sp_size) {
                width: 18px;
                margin: 0 10px;
              }

              &.sns_fb {
                width: 8px;
                @include max-screen($sp_size) {
                  width: 10px;
                }
              }

              img {
                vertical-align: middle;
              }
            }

            @media print {
              &.sp {
                display: none;                
              }
            }
          }

          .footer_text {
            margin: 12px 0;
            @include max-screen($sp_size) {
              margin-top: 0;
              padding: 0 10px;
            }
          }

          form {
            @include max-screen($sp_size) {
              padding: 0 10px;
              font-size: 0;
            }

            a {
              display: block;
              margin-top: 12px;
              @include max-screen($sp_size) {
                font-size: 12px;
              }
            }
          }

          &:last-of-type {
            @include max-screen($sp_size) {
              .footer_heading {
                color: $color_main_dark;
                font-size: 16px;
              }
              .footer_text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    &:last-child {
      .footer_inner {
        margin-top: 5px;
        @include max-screen($sp_size) {
          margin-top: 0;
          padding: 20px 10px;                
        }

        .footer_logo {
          float: right;          
          @include max-screen($sp_size) {
            float: none;
            margin-bottom: 20px;
            width: 90px;
          }
        }

        ul {
          @include flexbox;
          li {
            &:not(:first-child) {
              margin-left: 8px;
              &::before {
                content: "|";
                display: inline;
                margin-right: 8px;
                color: #000;
              }
            }

            @include max-screen($sp_size) {
              &:not(:first-child) {
                margin-left: 0;
                &::before {
                  content: none;
                }
              }
              &:first-child {
                width: 100%;
                margin-bottom: 5px;
              }
              &:last-child {
                margin-left: 8px;
                &::before {
                  content: "|";
                  display: inline;
                  margin-right: 8px;
                  color: #000;
                }
              }
            }

            a {
              font-size: 13px;
              @include max-screen($sp_size) {
                font-size: 14px;
              }
            }
          }
        }

        small {
          display: block;
          font-size: 13px;
          color: #000;
          margin-top: 20px;
          @include max-screen($sp_size) {
            font-size: 12px;
          }

          br {
            @media print {
              display: none;
            }            
          }
        }
      }
    }
  }
}

/* SP Menu
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
@include min-screen($pc_size) {
  .spMenu {
    display: none;
  }
}
@media print {
  .spMenu {
    display: none;
  }
}

@include max-screen($sp_size) {
  .spMenu {
    background-color: #fff;
    transform: translateX(-100%);
    width: 85%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all .5s;

    .menu_close {
      @include min-screen($pc_size) {
        display: none;
      }
      @include max-screen($sp_size) {
        display: none;
        width: 35px;
        height: 40px;
        background-image: url(../img/common/icon_menu_close.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 5px;
        right: -40px;
        z-index: 100;
        &.open {
          display: block;
        }
      }
    }

    .spMenu_inner {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;

      .username {
        background-color: $color_bg_gray;
        padding: 8px 2%;
        font-size: 14px;
      }
      .spMenu_head {
        @include flexbox;
        justify-content: center;
        padding: 10px 2%;
        .btn {
          width: 48%;
          height: 30px;
          border-radius: 2px;
          box-shadow: 0 1px 2px rgba(0,0,0,0.2);
          font-size: 12px;
          text-align: center;
          &:nth-child(even) {
            margin-left: 4%;
          }
          &:nth-child(n + 3) {
            margin-top: 10px;
          }
        }
      }
      .category_area {
        border-top: 2px solid $color_main;
        border-bottom: 2px solid $color_main;
        margin-top: 0;

        dl {
          dt {
            padding: 20px 10px 20px 2%;
          }
        }
      }
      .spMenu_help {
        @include flexbox;
        a {
          width: 50%;
          border-bottom: 1px solid #ddd;
          padding: 10px 2%;
          font-weight: bold;
          text-decoration: none;
          &:nth-child(even) {
            border-left: 1px solid #ddd;
          }
        }
      }
      .spMenu_close {
        margin: 30px auto;
        text-align: center;
        button {
          font-weight: bold;
          width: 180px;
          height: 30px;
          border: 1px solid #ddd;
          box-shadow: 1px 1px 0px rgba(0,0,0,0.2);
          background-image: url(../img/common/icon_cross.png);
          background-repeat: no-repeat;
          background-position: 10px center;
          background-size: 12px;
        }    
      }
    }
  }

  // Menu Open
  .wrapper {
    &.open {
      .spMenu {
        transform: translateX(0);      
      }
    }
  }
}

/* Icon
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.with_arrow_right {
  position: relative;
  svg {
    width: 8px;
    fill: $color_text;
    @include centering_vertically;
    right: 14px;
    display: none;
    @include max-screen($sp_size) {
      display: block;
    }
  }
}

.with_triangle {
  @include min-screen($pc_size) {
    margin-bottom: 5px;
    padding: 0 20px 0 12px;
    background-image: url(../img/common/icon_triangle_right.png);
    background-repeat: no-repeat;
    background-position: left center;              
  }
  @include max-screen($sp_size) {
    border-top: 1px dotted $color_border_lightgray;
    &:first-child {
      border-top-style: solid;
    }
  }

  a {
    display: block;
    text-decoration: none;
    @include max-screen($sp_size) {
      padding: 10px 20px 10px 25px;
      font-weight: bold;
    }
    .number {
      font-weight: normal;
    }
  }
}